







































































































































































































/* eslint-disable no-undef */

import { Loader } from '@googlemaps/js-api-loader'
import { ContactMap, Showroom } from '@/inc/types'
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  PropType,
} from '@vue/composition-api'

import locale from '@/composables/locale'
import pinActive from '@/assets/images/pinActive.png'
import pinHouse from '@/assets/images/pinHouse.png'

const API_KEY = process.env.VUE_APP_GMAPS

interface ShowroomMarker extends google.maps.Marker {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  position: any
  lat: string
  lng: string
  data: Showroom
  id: number
  setMap(map)
}

const enableEnterKey = (input: HTMLInputElement) => {
  /* Store original event listener */
  const _addEventListener = input.addEventListener

  const addEventListenerWrapper = (type, listener) => {
    /* Store existing listener function */
    const _listener = listener
    // eslint-disable-next-line no-param-reassign
    listener = event => {
      /* Simulate a 'down arrow' keypress if no address has been selected */
      const suggestionSelected =
        document.getElementsByClassName('pac-item-selected').length
      if (event.key === 'Enter' && !suggestionSelected) {
        const e = new KeyboardEvent('keydown', {
          key: 'ArrowDown',
          code: 'ArrowDown',
          keyCode: 40,
        })
        _listener.apply(input, [e])
        input.blur()
      }
      _listener.apply(input, [event])
    }
    _addEventListener.apply(input, [type, listener])
  }

  input.addEventListener = addEventListenerWrapper
}

export default defineComponent({
  name: 'ContactMap',
  props: {
    content: {
      type: Object as PropType<ContactMap>,
      required: true,
    },
    showrooms: {
      type: Array as PropType<Showroom[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const zip = ref<string>('')

    const mapEl = ref<HTMLElement>()
    const searchInputEl = ref<HTMLInputElement>()

    let gmap: google.maps.Map
    const markers: ShowroomMarker[] = []

    let autocomplete: google.maps.places.Autocomplete
    let autocompleteMarker: google.maps.Marker

    const createMarkers = (map: google.maps.Map) => {
      const bounds = new google.maps.LatLngBounds()

      props.showrooms.forEach((showroom, index) => {
        const marker: ShowroomMarker = new google.maps.Marker({
          id: index, // TBD
          position: {
            lat: parseFloat(showroom.address.map.lat),
            lng: parseFloat(showroom.address.map.lng),
          },
          map,
          icon: {
            url: pinActive,
            scaledSize: new google.maps.Size(30, 40), // Scaled size
          },
        } as google.maps.MarkerOptions) as ShowroomMarker

        marker.addListener('click', () => {
          onMarkerClick(marker)
        })
        markers.push(marker)
        bounds.extend(marker.position)
      })

      map.fitBounds(bounds, { top: 80, right: 10, left: 10, bottom: 20 })
    }

    const sortShowroomsByDistance = async (
      address: google.maps.LatLng,
      map: google.maps.Map
    ) => {
      const service = new google.maps.DistanceMatrixService()
      const showrooms = [...props.showrooms]
      const destinations = showrooms.map(
        showroom =>
          new google.maps.LatLng(
            parseFloat(showroom.address.map.lat),
            parseFloat(showroom.address.map.lng)
          )
      )

      await service.getDistanceMatrix(
        {
          origins: [address],
          destinations,
          travelMode: 'DRIVING' as google.maps.TravelMode,
        },
        response => {
          const results = response!.rows[0].elements

          for (let i = 0; i < results.length; i++) {
            showrooms[i].distance = results[i].distance.value
          }
        }
      )

      showrooms.sort((a, b) => (a.distance! > b.distance! ? 1 : -1))

      const bounds = new google.maps.LatLngBounds()
      bounds.extend(address)

      for (let i = 0; i < 3; i++) {
        bounds.extend(
          new google.maps.LatLng(
            parseFloat(showrooms[i].address.map.lat),
            parseFloat(showrooms[i].address.map.lng)
          )
        )
      }

      map.fitBounds(bounds, { top: 50, right: 20, left: 20, bottom: 20 })
      ctx.emit('showrooms:autocomplete', showrooms)
    }

    const initAutoComplete = (
      map: google.maps.Map,
      input: HTMLInputElement
    ) => {
      autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: ['be', 'lu'] },
        types: ['(regions)'],
      })

      autocomplete.bindTo('bounds', map)
      autocomplete.setFields(['geometry'])
      autocomplete.addListener('place_changed', () =>
        onPlaceChanged(autocomplete, map)
      )
    }

    const onMarkerClick = (marker: ShowroomMarker) => {
      const selectedShowroom = props.showrooms[marker.id]

      if (selectedShowroom) {
        ctx.emit('showrooms:selected', selectedShowroom)

        resetMarkers()

        const iconActive = {
          url: pinActive,
          scaledSize: new google.maps.Size(45, 60), // Scaled size
        }

        marker.setIcon(iconActive)
      }
    }

    const onSubmit = () => {
      // WIP
      // const query = searchInputEl.value!.value
      // const service = new google.maps.places.AutocompleteService()
      // service.getPlacePredictions(
      //   {
      //     input: query,
      //     componentRestrictions: { country: ['be', 'lu'] },
      //     types: ['(regions)'],
      //   },
      //   (places: google.maps.places.AutocompletePrediction[] | null) => {
      //     if (!places) {
      //       return
      //     }
      //     searchInputEl.value!.value = places[0].description
      //       ? places[0].description
      //       : query
      //     // autocomplete.setPlace(place[0])
      //   }
      // )
      //
    }

    const onPlaceChanged = (
      autocomplete: google.maps.places.Autocomplete,
      map: google.maps.Map
    ) => {
      if (!autocomplete.getPlace()!.geometry) {
        return
      }

      const autocompleteResult = new google.maps.LatLng(
        autocomplete.getPlace().geometry!.location!.lat(),
        autocomplete.getPlace().geometry!.location!.lng()
      )

      resetMarkers()

      if (autocompleteMarker) {
        autocompleteMarker.setMap(null) // Remove previous marker
      }

      autocompleteMarker = new google.maps.Marker({
        position: {
          lat: autocomplete.getPlace().geometry!.location!.lat(),
          lng: autocomplete.getPlace().geometry!.location!.lng(),
        },
        map,
        icon: {
          url: pinHouse,
        },
        clickable: false,
      })

      sortShowroomsByDistance(autocompleteResult, map)

      window.scrollTo({
        top: mapEl.value!.offsetTop - 200,
        left: 0,
        behavior: 'smooth',
      })
    }

    const resetMarkers = () => {
      const icon = {
        url: pinActive,
        scaledSize: new google.maps.Size(30, 40), // Scaled size
      }

      markers.forEach(el => {
        el.setIcon(icon)
      })
    }

    onMounted(async () => {
      if (!mapEl.value) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(Loader as any).instance) {
        // Loader has been reset
        delete window.google?.maps
      }

      const loader = new Loader({
        apiKey: API_KEY as string,
        language: ctx.root.$route.params.lang,
        version: 'weekly',
        libraries: ['places', 'geometry'],
      })

      try {
        await loader.load()
      } catch (error) {
        console.error('GMAPS Error', error)
      }

      gmap = new google.maps.Map(mapEl.value!, {
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#a6abaf',
              },
              {
                weight: '2',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'administrative.neighborhood',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
        ],
      })

      createMarkers(gmap)

      initAutoComplete(gmap, searchInputEl.value!)

      enableEnterKey(searchInputEl.value!)
    })

    onBeforeUnmount(() => {
      markers.forEach(marker => {
        google.maps?.event.clearListeners(marker, 'click')
      })

      // Cleanup after gmap places lib
      document.body
        .querySelectorAll('.pac-container')
        .forEach(el => el && el.parentNode?.removeChild(el))
    })

    return {
      locale,
      mapEl,
      searchInputEl,
      zip,
      onSubmit,
    }
  },
})
