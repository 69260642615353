





















































































import {
  defineComponent,
  ref,
  computed,
  PropType,
  onMounted,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import type { ContactMap as Map, Showroom, Link } from '@/inc/types'

import ContactShowroom from '@/components/contact/Showroom.vue'
import ContactMap from '@/components/contact/Map.vue'
import ContactStepContainer from '@/components/contact/step/Container.vue'

interface StepShowroom extends Map {
  items: Showroom[]
  labels: Record<string, string>
  next: string
  prev: string
  submit: Link
  info?: {
    title: string
    htmltext: string
  }
}

export default defineComponent({
  name: 'ContactStepShowroom',
  components: { ContactStepContainer, ContactMap, ContactShowroom },
  props: {
    content: {
      type: Object as PropType<StepShowroom>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $storage } = ctx.root.$options
    const { showroom } = useGetters('steps', ['showroom'])
    const selectedShowroom = ref<Showroom | null>(null)
    const nearestShowrooms = ref<Showroom[]>([])
    const status = ref<'nearest' | 'selected' | 'all'>('all')

    // Get list of showrooms to display based on status
    const showroomsToDisplay = computed(() => {
      let results = nearestShowrooms.value.length
        ? nearestShowrooms.value
        : props.content.items

      // Remove selected showroom from results, as it is displayed separately
      if (selectedShowroom.value) {
        results = results.filter(
          result => result.value !== selectedShowroom.value?.value
        )
      }

      switch (status.value) {
        //  When status is nearest: show only selected showroom
        case 'selected':
          results = []
          break
        //  When status is nearest: show only 3 showrooms
        case 'nearest':
          results = results.filter((_r, i) => i < 3)
          break
        default:
          break
      }

      return results
    })

    // Show complete list of showrooms
    const showAll = () => (status.value = 'all')

    // Handle showroom selection from map: select showroom
    const onMapSelected = (showroom: Showroom) => {
      selectedShowroom.value = showroom
      status.value = 'selected'

      setTimeout(() => {
        document.querySelector('.step-showroom__results')?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 300)
    }

    // Handle map autocomplete: display nearest showrooms
    const onMapAutocomplete = (showrooms: Showroom[]) => {
      status.value = 'nearest'
      nearestShowrooms.value = showrooms
      selectedShowroom.value = null
    }

    const onNextStep = () => ctx.emit('submit')

    onMounted(() => {
      // Get saved showroom from state
      const savedShowroom = showroom.value($storage)

      if (savedShowroom) {
        selectedShowroom.value =
          props.content.items.find(
            s => s.value === showroom.value($storage)?.value
          ) || null
        status.value = selectedShowroom.value ? 'selected' : 'all'
      }
    })

    return {
      selectedShowroom,
      showroomsToDisplay,
      nearestShowrooms,
      status,
      onMapSelected,
      onMapAutocomplete,
      onNextStep,
      showAll,
    }
  },
})
