





























































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { Showroom } from '@/inc/types'
import { useActions } from '@u3u/vue-hooks'
import { push, GtmLayer } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'ContactShowroom',
  components: { UiPicture },
  props: {
    content: {
      type: Object as () => Showroom,
      required: true,
    },
    next: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },

  setup(props, ctx) {
    const el = ref<HTMLElement | null>(null)
    const { addShowroom } = useActions('steps', ['addShowroom'])

    const nextStep = () => {
      const { value, address, title } = props.content
      const { zip } = address
      const showroom = {
        zip,
        value,
        title,
      }

      if (ctx.root.$options.$storage) {
        addShowroom({
          data: showroom,
          storage: ctx.root.$options.$storage,
        })
      }

      ctx.emit('next')
    }

    const pushAddress = () => {
      const { address, value } = props.content
      const { street, number, zip, city, country } = address

      const layer: GtmLayer = {
        event: 'click_showroom',
        clickType: 'address',
        clickTypeValue: `${street} ${number}, ${zip} ${city}, ${country}`,
        showroomName: value,
      }
      push(layer)
    }

    onMounted(() => {
      el.value?.classList.add('is-visible')
    })

    return {
      el,
      nextStep,
      pushAddress,
    }
  },
})
