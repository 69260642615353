





































































import { defineComponent, ref, onBeforeMount } from '@vue/composition-api'
import { useActions } from '@u3u/vue-hooks'
import { ValidationObserver } from 'vee-validate'
import { StorageUtils } from '@/inc/plugins/storage'

import FormInput from '@/components/form/Input.vue'
import FormFeedback from '@/components/form/Feedback.vue'

import type { PersonalData } from '@/inc/store/modules/steps'

export default defineComponent({
  name: 'PartnerStep0',
  components: {
    FormInput,
    FormFeedback,
    ValidationObserver,
  },
  props: ['content'],
  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const error = ref(false)
    const sending = ref(false)

    const { $storage } = ctx.root.$options
    const { cleanSteps } = useActions('steps', ['cleanSteps']) as {
      cleanSteps: (payload: { storage: StorageUtils }) => Promise<void>
    }
    const { addPersonal, addProject } = useActions('steps', [
      'addPersonal',
      'addProject',
    ])

    const onSubmitClick = () => {
      const el = document.querySelector('.is-invalid')

      if (!el) {
        return
      }

      const elTop = el.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: elTop - 100,
        left: 0,
        behavior: 'smooth',
      })
    }

    const onSubmit = () => {
      const formData = new FormData(form.value!)
      const data: Partial<PersonalData> = {}

      for (const pair of formData.entries()) {
        const [name, value] = pair
        if (typeof name === 'string') {
          data[name] = value
        }
      }

      // Save data in session to pre-fill fields
      addPersonal({
        data,
        storage: $storage!,
      })
      addProject({
        data: {
          projectName: data.projectName,
        },
        storage: $storage!,
      })

      ctx.root.$router.push(props.content.next)
    }

    onBeforeMount(() => {
      cleanSteps({ storage: $storage! })
    })

    return {
      form,
      error,
      sending,
      onSubmit,
      onSubmitClick,
    }
  },
})
