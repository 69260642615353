





































































































































































































import { defineComponent, ref, onMounted, PropType } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import { ValidationObserver } from 'vee-validate'

import { StorageUtils } from '@/inc/plugins/storage'
import { getApiUrl } from '@/inc/app.config'
import { fetch } from '@/inc/utils/cache'
import { prefersReducedMotion } from '@/inc/utils'

import ContactStepContainer from '@/components/contact/step/Container.vue'
import FormCategories from '@/components/form/Categories.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import FormFeedback from '@/components/form/Feedback.vue'
import FormInput from '@/components/form/Input.vue'
import FormSelect from '@/components/form/Select.vue'
import FormTextarea from '@/components/form/Textarea.vue'
import FormProjects from '@/components/form/Projects.vue'
import FormFiles from '@/components/form/Files.vue'
import Moodboard from '@/components/contact/Moodboard.vue'
import FormRecaptcha from '@/components/form/Recaptcha.vue'
import FormSideTooltip from '@/components/form/SideTooltip.vue'
import UiLoadingDots from '@/components/ui/LoadingDots.vue'

import type { ProjectData } from '@/inc/store/modules/steps'
import type {
  File,
  MoodboardBuilderPreviewContent,
  SelectOption,
} from '@/inc/types'

interface StepProject {
  action: string
  back: {
    label: string
    icon: string
  }
  inputs: {
    name: string
    slug: string
    type: string
    label: string
    placeholder?: string
    required: boolean
    options?: Array<
      SelectOption & {
        sfValue: string
      }
    >
    tooltip?: string
    buttonLabel?: string
    modifiers?: string
  }[]
  moodboard: {
    htmltext: string
    label: string
    title: string
  }
  next: string
  prev: string
  realisations: unknown // TODO: import type from component
  source: string
  submit: {
    label: string
    icon: string
  }
  title: string
  uploads: unknown // TODO: import type from component
}

export default defineComponent({
  name: 'ContactStepProject',

  components: {
    ContactStepContainer,
    FormCategories,
    FormCheckbox,
    FormFeedback,
    FormInput,
    FormSelect,
    FormTextarea,
    FormProjects,
    FormFiles,
    FormRecaptcha,
    FormSideTooltip,
    ValidationObserver,
    Moodboard,
    UiLoadingDots,
  },

  props: {
    content: {
      type: Object as PropType<StepProject>,
      required: true,
    },
  },

  setup(props, ctx) {
    // Step data
    const { $storage } = ctx.root.$options
    const { project, status, message } = useGetters('steps', [
      'project',
      'status',
      'message',
    ])
    const { addProject } = useActions('steps', ['addProject'])
    const savedStep = ref<Partial<ProjectData>>(
      project.value($storage!) ? project.value($storage!) : {}
    )
    const savedFiles = ref<File[]>(
      savedStep.value?.files ? (savedStep.value.files as File[]) : []
    )
    const { chrome } = useGetters(['chrome'])
    const moodboard = ref<MoodboardBuilderPreviewContent['moodboard'] | null>(
      null
    )
    let updatedFiles: File[] = []
    let projectSelection: string[] | undefined =
      savedStep.value.selectedRealisation

    // Elements
    const formEl = ref<HTMLFormElement | null>(null)
    const categoriesEl = ref<InstanceType<typeof FormCategories> | null>(null)
    const isUploading = ref(false)
    const categoryInput = props.content.inputs.find(i => i.name === 'category')
    const messageInput = props.content.inputs.find(i => i.name === 'message')
    const projectNameInput = props.content.source
      ? props.content.inputs.find(i => i.name === 'projectName')
      : null
    const surveyInput = props.content.inputs.find(i => i.name === 'survey')

    // Convert array of files to links (for Salesforce user)
    const convertFilesToLinks = (files: File[]) => {
      let result = ''

      if (files && files.length) {
        files.forEach(file => {
          result += `<li><a href="${file.url}" target="_blank">${file.savedName}</a></li>`
        })
      }

      return result
    }

    // Convert project selection to links (for Salesforce user)
    const convertSelectionToLinks = (selection: string[]) => {
      let result = ''

      if (selection && selection.length) {
        selection.forEach(project => {
          result += `<li><a href="${process.env.VUE_APP_DOMAIN}${project}" target="_blank">${project}</a></li>`
        })
      }

      return result
    }

    // Save current step to session
    const saveStep = () => {
      if (!categoriesEl.value) {
        return
      }

      const { selection, result } = categoriesEl.value
      const message = (
        formEl.value?.querySelector('.form-textarea') as HTMLTextAreaElement
      ).value
      const files =
        !updatedFiles.length && savedStep.value
          ? savedStep.value.files
          : updatedFiles

      const data: ProjectData = {
        ...project.value($storage!),
        categories: {
          items:
            (selection as Array<
              SelectOption & {
                sfValue: string
              }
            >) || [],
          value: result || '',
        },
        message,
        files,
        attachments: convertFilesToLinks(files as File[]),
        selectedRealisation: projectSelection,
        inspirations: convertSelectionToLinks(projectSelection as string[]),
        moodboard: moodboard.value?.code,
      }

      addProject({
        data,
        storage: $storage as StorageUtils,
      })
    }

    // Handle project selection update
    const onSelectionUpdate = (payload: string[]) => {
      projectSelection = payload

      ctx.root.$nextTick(() => saveStep())
    }

    // Handle upload start: lock submit
    const onUploadStart = () => (isUploading.value = true)

    // Handle upload succes: save files and unlock submit
    const onUploadDone = (payload: File[]) => {
      if (payload) {
        updatedFiles = payload
      }

      saveStep()
      isUploading.value = false
    }

    // Update value in step
    const onChange = (value: unknown, name: string) => {
      addProject({
        data: {
          ...project.value($storage!),
          [name]: value,
        },
        storage: $storage as StorageUtils,
      })
    }

    // Handle submit when the form is valid
    // Save step and emit submit to parent
    const onSubmit = () => {
      saveStep()
      ctx.emit('submit')
    }

    // Handle click on submit button:
    // 1) Check if an input is invalid
    // 2) Track click
    const onSubmitClick = () => {
      const el = formEl.value?.querySelector('.is-invalid')

      // If there is no invalid field, we can proceed
      if (!el) {
        return
      }

      const elTop = el.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: elTop - 100,
        left: 0,
        behavior: prefersReducedMotion() ? 'auto' : 'smooth',
      })
    }

    // If we do have a moodboard query in url, try to fetch corresponding moodboard.
    // If we do have a corresponding moodboard, display a little readonly cutout letting the user
    // know that its quote is linked to moodboard.
    const fetchMoodboard = async () => {
      const moodboardCode = ctx.root.$route.query.moodboard
      if (moodboardCode) {
        const url = `${getApiUrl()}/moodboard/view/${moodboardCode}`

        try {
          const response = await fetch(ctx.root.$route.fullPath, url)

          const data =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (response?.data?.content as MoodboardBuilderPreviewContent)
              ?.moodboard

          if (data) {
            moodboard.value = data
          }
        } catch (error) {
          console.warn('No moodboard found', moodboardCode)
          console.error(error)
        }
      }
    }

    onMounted(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: prefersReducedMotion() ? 'auto' : 'smooth',
      })

      // TODO: improve
      ctx.root.$ee.$on('selection:update', onSelectionUpdate)

      fetchMoodboard()
    })

    return {
      status,
      message,
      chrome,
      categoryInput,
      messageInput,
      projectNameInput,
      surveyInput,
      formEl,
      categoriesEl,
      isUploading,
      savedFiles,
      savedStep,
      saveStep,
      onChange,
      onSubmit,
      onSubmitClick,
      onUploadStart,
      onUploadDone,
      moodboard,
    }
  },
})
