





















































import axios from 'axios'
import { defineComponent, onBeforeMount } from '@vue/composition-api'
import { useGetters, useActions } from '@u3u/vue-hooks'

import i18n from '@/inc/i18n'
import { StorageUtils } from '@/inc/plugins/storage'
import { push, campaignManager, GtmLayer } from '@/inc/utils'

import ContactSteps from '@/components/contact/Steps.vue'
import ContactStepPersonal from '@/components/contact/step/Personal.vue'
import ContactStepProject from '@/components/contact/step/Project.vue'
import ContactStepShowroom from '@/components/contact/step/Showroom.vue'
import FlexibleBenefits from '@/components/flexible/Benefits.vue'
import PartnerStep0 from '@/components/partner/Step0.vue'
import UiPill from '@/components/ui/Pill.vue'

import type { ProjectData } from '@/inc/store/modules/steps'

export default defineComponent({
  name: 'ContactNewProject',
  components: {
    UiPill,
    ContactSteps,
    ContactStepShowroom,
    ContactStepPersonal,
    ContactStepProject,
    FlexibleBenefits,
    PartnerStep0,
  },
  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { $router, $route, $options, $cookie } = ctx.root
    const { showroom, personal, project } = useGetters('steps', [
      'showroom',
      'personal',
      'project',
    ])
    const { addShowroom, cleanSteps, initState, updateStatus, updateMessage } =
      useActions('steps', [
        'addShowroom',
        'cleanSteps',
        'initState',
        'updateStatus',
        'updateMessage',
      ])
    const template = $route.name
    const { $storage } = $options

    // Format attachments as <ol> for Salesforce user
    const getAttachments = (data: ProjectData) => {
      const keys = ['attachments', 'inspirations', 'moodboardUrl']
      let attachments = ''

      keys.forEach(key => {
        if (!data[key]) {
          return
        }

        attachments += `${key.charAt(0).toUpperCase()}${key.slice(1)}: <ol>${
          data[key]
        }</ol>`
      })

      // Append moodboard URL to attachments list if it exists
      if (data.moodboard) {
        const lang = ctx.root.$i18n.locale
        const moodboardUrl = `${process.env.VUE_APP_DOMAIN}/${lang}/moodboard-builder/${data.moodboard}`

        // There will only be a single moodboard but an <ol> layout is requested
        // eslint-disable-next-line max-len
        attachments += `Moodboards: <ol><li><a href="${moodboardUrl}"
       target="_blank">${moodboardUrl}</a><li></ol>`
      }

      return attachments
    }

    // Post quote request data to API
    const submitQuoteRequest = async () => {
      const showroomStep = showroom.value($storage!)
      const personalStep = personal.value($storage!)
      const projectStep = project.value($storage!)
      const isPartner = $route.name === 'partner-step3'

      // Check if step 1 exists
      if (!showroomStep?.value) {
        updateStatus('error')
        updateMessage(i18n.messages[i18n.locale]?.['contact-error-showroom'])

        return
      }

      // Check if step 2 exists
      if (
        !personalStep ||
        !personalStep.email ||
        !personalStep.lastName ||
        !personalStep.firstName
      ) {
        updateStatus('error')
        updateMessage(i18n.messages[i18n.locale]?.['contact-error-infos'])

        return
      }

      const { action } = content.value.step3

      const data = {
        // General
        lang: `${$route.params.lang}-${process.env.VUE_APP_COUNTRY}`,
        formId: isPartner ? 'partner-matexi' : 'new-project',
        analyticsId: `id_${Math.random().toString(36).substr(2, 36)}`,
        visitor_id908712: $cookie.get('visitor_id908712'), // eslint-disable-line camelcase
        location: process.env.VUE_APP_DOMAIN,
        // Hubspot data
        hutk: $cookie.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: content.value.title,
        // Step 1: showroom
        showroom: showroomStep.value,
        // Step 2 : personal info
        ...personalStep,
        valid: '', // override boolean to prevent server error
        // Step 3 : project info
        message: projectStep.message,
        moodboard: projectStep.moodboard,
        projectTypes: projectStep.categories?.value,
        survey: projectStep.survey,
        attachments: getAttachments(projectStep),
        // Partner
        source: content.value.step3?.source,
        // Campaign data
        ...campaignManager.getFormData('quote_request'),
      }

      console.log('[onSubmit] Sending to API... 📈', data)

      updateStatus('submitting')

      try {
        const response = await axios.post(action, data)

        if (response.status < 300) {
          // Push global event to datalayer
          trackStep('global')

          // Reset form steps
          cleanSteps({ storage: $storage! })
          updateStatus('idle')

          // Go to thank you page
          ctx.root.$router.push(content.value.step3.next)
        } else {
          updateStatus('error')
          updateMessage(null)
        }
      } catch (err) {
        updateStatus('error')
        updateMessage(null)
      }
    }

    const trackStep = (
      step: 'showroom' | 'personal' | 'project' | 'global'
    ) => {
      let layer: Partial<GtmLayer> = {
        showroomName: showroom.value($storage).value,
      }

      switch (step) {
        case 'showroom':
          layer.event = 'generate_lead_step1'
          break
        case 'personal':
          layer.event = 'generate_lead_step2'
          layer.country = personal.value($storage).country || ''
          layer.postalCode = personal.value($storage).zip || ''
          break
        case 'global':
          layer = {
            ...layer,
            event: 'generate_lead',
            // Personal
            country: personal.value($storage).country || '',
            postalCode: personal.value($storage).zip || '',
            // Categories
            categoryClicked:
              project.value($storage).categories?.items.length || 0,
            realisationCategoryName:
              project
                .value($storage)
                .categories?.items.map(item => item.sfValue) || [],
            // Inspirations
            realisationName: project.value($storage).selectedRealisation
              ? [...project.value($storage).selectedRealisation]
              : undefined,
            realisationClicked:
              project.value($storage).selectedRealisation?.length || 0,
            // Upload types
            /* eslint-disable indent */
            itemValue: project.value($storage).files
              ? ([
                  ...new Set(
                    project.value($storage).files.map(file => file.ext)
                  ),
                ] as string[])
              : [],
            /* eslint-enable indent */
            // Campaign data
            ...campaignManager.getFormData('quote_request'),
          }
          break
        default:
          break
      }

      push(layer as GtmLayer)
    }

    const onShowroomSubmit = () => {
      trackStep('showroom')
    }

    const onPersonalSubmit = () => {
      trackStep('personal')
    }

    const onProjectSubmit = () => {
      submitQuoteRequest()
    }

    onBeforeMount(() => {
      initState(ctx.root.$options.$storage)

      // Select showroom based on query
      if (typeof $route.query?.showroom === 'string') {
        const showrooms: {
          title?: string
          label: string
          zip: string
          value: string
        }[] = content.value.step1?.items || content.value.step2?.showrooms || []

        const showroom = showrooms.find(
          item => item.zip === $route.query.showroom
        )

        if (showroom) {
          addShowroom({
            data: {
              title: showroom.title || showroom.label,
              zip: showroom.zip,
              value: showroom.value,
            },
            storage: ctx.root.$options.$storage as StorageUtils,
          })
        }
      }

      // Redirect user if previous steps are incomplete
      const routeStep = parseInt($route.params.step, 10)
      let currentStep = routeStep

      // To access step 3 (project), user must have completed step 3 (personal info)
      if (routeStep === 3 && !personal.value($storage)?.valid) {
        currentStep = 2
      }

      // To access step 2 (personal), user must have completed step 1 (showroom)
      if (routeStep >= 2 && !showroom.value($storage)) {
        currentStep = 1
      }

      // There is no further step than 3
      if (routeStep > 3) {
        currentStep = 1
      }

      // Redirect to
      if (routeStep !== currentStep) {
        $router.replace({
          name: `new-step${currentStep}`,
          params: { ...$route.params, step: `${currentStep}` },
        })
      }
    })

    return {
      content,
      template,
      onShowroomSubmit,
      onPersonalSubmit,
      onProjectSubmit,
    }
  },
})
