













import {
  defineComponent,
  PropType,
  ref,
  onUnmounted,
} from '@vue/composition-api'
import { ContactStep as ContactStepType } from '@/inc/types'
import { useGetters } from '@u3u/vue-hooks'
import ContactStep from './Step.vue'

import type {
  PersonalData,
  ShowroomData,
  ProjectData,
} from '@/inc/store/modules/steps'

export default defineComponent({
  name: 'ContactSection',
  components: {
    ContactStep,
  },
  props: {
    content: {
      type: Array as PropType<ContactStepType[]>,
      required: true,
    },
  },
  setup(_props, ctx) {
    const { $storage } = ctx.root.$options
    const { state, showroom, personal, project } = useGetters('steps', [
      'state',
      'showroom',
      'personal',
      'project',
    ])
    const showroomStep = ref<Partial<ShowroomData>>(showroom.value($storage!))
    const personalStep = ref<Partial<PersonalData>>(personal.value($storage!))
    const projectStep = ref<Partial<ProjectData>>(project.value($storage!))

    const labels = ref<Record<string, string | undefined | null>>({
      showroom: showroom.value($storage!)?.title,
      name:
        /* eslint-disable indent */
        personal.value($storage!)?.firstName &&
        personal.value($storage!)?.lastName
          ? `${personal.value($storage!)?.firstName} ${
              personal.value($storage!)?.lastName
            }`
          : '',
      /* eslint-enable indent */
      category: project.value($storage!)?.categories?.items?.[0]?.label,
    })

    // Subscribe to store update
    // Note: this has proven more reliable than using a computed with a ref to the state
    const unsubscribe = ctx.root.$store.subscribe(mutation => {
      if (!mutation?.payload || !mutation.type.includes('steps/')) {
        return
      }

      const { title, firstName, lastName, categories } = mutation.payload

      // First step title is the showroom name
      if (title) {
        // Info: The title value in showroom step payload should never be undefined
        labels.value.showroom = title
      }

      // Second step title is both the first and last name
      if (firstName && lastName) {
        labels.value.name = `${firstName} ${lastName}`
      } else if (
        Object.hasOwn(mutation.payload, 'firstName') ||
        Object.hasOwn(mutation.payload, 'lastName')
      ) {
        // Info: Do not nullify the name label when firstName/lastName don't exist
        // in the payload because it means the payload corresponds to a different step
        labels.value.name = null
      }

      // Third step title is the first selected category's title
      if (categories?.items?.length) {
        labels.value.category = categories.items[0].label
      } else if (Object.hasOwn(mutation.payload, 'categories')) {
        // Info: Do not nullify the category label when categories don't exist
        // in the payload because it means the payload corresponds to a different step
        labels.value.category = null
      }
    })

    onUnmounted(() => {
      unsubscribe()
    })

    return {
      labels,
      projectStep,
      personalStep,
      showroomStep,
      state,
    }
  },
})
