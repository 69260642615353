




























































































































import { defineComponent, ref, PropType, onMounted } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import { ValidationObserver } from 'vee-validate'

import { prefersReducedMotion } from '@/inc/utils'

import ContactStepContainer from '@/components/contact/step/Container.vue'
import FormInput from '@/components/form/Input.vue'
import FormSelect from '@/components/form/Select.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import FormFeedback from '@/components/form/Feedback.vue'
import FormSideTooltip from '@/components/form/SideTooltip.vue'

import type { SelectOption } from '@/inc/types'
import type { PersonalData } from '@/inc/store/modules/steps'

interface StepPersonal {
  action: string
  back: {
    label: string
    icon: string
  }
  headline?: string
  inputs: {
    name: string
    slug: string
    type: string
    label: string
    placeholder?: string
    required: boolean
    options?: SelectOption[]
    tooltip?: string
    buttonLabel?: string
    modifiers?: string
  }[]
  next: string
  prev: string
  source: string
  submit: {
    label: string
    icon: string
  }
  title: string
}

export default defineComponent({
  name: 'ContactStepPersonal',
  components: {
    ContactStepContainer,
    FormInput,
    FormSelect,
    FormCheckbox,
    FormFeedback,
    FormSideTooltip,
    ValidationObserver,
  },
  props: {
    content: {
      type: Object as PropType<StepPersonal>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $storage } = ctx.root.$options
    const { personal } = useGetters('steps', ['personal'])
    const { addPersonal } = useActions('steps', ['addPersonal'])
    const savedStep = ref<Partial<PersonalData>>(
      personal.value($storage!) ? personal.value($storage!) : {}
    )

    // Handle user input
    // Save data to session
    const onInputChange = (value: string, name: string) => {
      savedStep.value[name] = value

      addPersonal({
        data: savedStep.value,
        storage: $storage!,
      })
    }

    // Handle click on submit button
    const onSubmitClick = () => {
      const invalidEl = document.querySelector('.is-invalid')

      if (!invalidEl) {
        return
      }

      // Set valid as false
      addPersonal({
        data: {
          ...savedStep.value,
          valid: false,
        },
        storage: $storage!,
      })

      // Scroll to invalid element
      const top = invalidEl.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: top - 100,
        left: 0,
        behavior: prefersReducedMotion() ? 'auto' : 'smooth',
      })
    }

    // Handle form submit (when form does not contain errors)
    const onSubmit = () => {
      // Save info to session
      addPersonal({
        data: {
          ...savedStep.value,
          valid: true,
        },
        storage: $storage!,
      })

      // Emit event to parent for tracking
      ctx.emit('submit')

      // Navigate to next step
      ctx.root.$router.push({ path: props.content.next })
    }

    onMounted(() => {
      // Get saved data from state
      // TODO: fix
      if (personal.value($storage)) {
        // savedStep.value = personal.value($storage)
      }
    })

    return {
      savedStep,
      onSubmit,
      onInputChange,
      onSubmitClick,
      ...useGetters(['chrome']),
    }
  },
})
