











































import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import { ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules.umd.js'
import FormFeedback from '@/components/form/Feedback.vue'

import type { SelectOption } from '@/inc/types'

extend('required', {
  ...required,
  message: 'form-required',
})

export default defineComponent({
  name: 'FormCategories',
  components: {
    FormFeedback,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: false,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    value: {
      type: Array as PropType<SelectOption[]>,
      required: false,
      default: () => [],
    },
    required: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, ctx) {
    const validator = ref<InstanceType<typeof ValidationProvider> | null>(null)
    const selection = ref(props.value || [])

    // Computes selection as a string
    const result = computed(() => {
      let v = ''

      if (selection.value.length) {
        v = selection.value.map(value => value.sfValue).join('; ')
      }

      // Sync value manually
      // Having more than one input confuses ValidationProvider automatic sync
      validator.value?.syncValue(v)

      return v
    })

    // Add/remove option from selection
    const onChange = (option: SelectOption) => {
      const selectedOption = selection.value.find(
        o => o.sfValue === option.sfValue
      )

      if (selectedOption) {
        // Remove option
        selection.value.splice(selection.value.indexOf(selectedOption), 1)
      } else {
        // Add option
        selection.value.push(option)
      }

      ctx.emit('input', selection.value)
      ctx.root.$nextTick(() => validator.value?.validate())
    }

    return {
      validator,
      selection,
      result,
      onChange,
    }
  },
})
