


















import { PropType, defineComponent } from '@vue/composition-api'

interface Benefits {
  items: {
    icon: string
    text: string
  }[]
}

export default defineComponent({
  name: 'FlexibleBenefits',
  props: {
    content: {
      type: Object as PropType<Benefits>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
