





































import { defineComponent, onMounted, PropType } from '@vue/composition-api'

interface Info {
  title: string
  htmltext: string
}

export default defineComponent({
  name: 'ContactStepContainer',
  components: {},
  props: {
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    total: {
      type: Number,
      default: 3,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    info: {
      type: Object as PropType<Info>,
      required: false,
      default: undefined,
    },
  },

  setup() {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    })

    return {}
  },
})
