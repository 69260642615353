var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContactStepContainer',{staticClass:"step-project",attrs:{"title":_vm.content.title,"index":2,"total":3},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationObserver',{staticClass:"wrapper",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{ref:"formEl",staticClass:"form mt-m",attrs:{"method":"POST","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form__fieldset"},[(_vm.categoryInput)?_c('FormCategories',{ref:"categoriesEl",staticClass:"form__input",attrs:{"name":_vm.categoryInput.name,"options":_vm.categoryInput.options || [],"label":_vm.categoryInput.label,"required":_vm.categoryInput.required,"value":_vm.savedStep && _vm.savedStep.categories
                ? _vm.savedStep.categories.items
                : []},on:{"input":_vm.saveStep}}):_vm._e(),(_vm.projectNameInput)?_c('FormInput',{staticClass:"form__input",attrs:{"name":_vm.projectNameInput.name,"label":_vm.projectNameInput.label,"placeholder":_vm.projectNameInput.placeholder,"required":_vm.projectNameInput.required,"data":_vm.savedStep ? _vm.savedStep[_vm.projectNameInput.name] : null},on:{"change":_vm.saveStep}}):_vm._e(),(_vm.messageInput)?_c('div',{staticClass:"form__input"},[(_vm.messageInput)?_c('FormTextarea',{attrs:{"name":_vm.messageInput.name,"label":_vm.messageInput.label,"placeholder":_vm.messageInput.placeholder,"required":_vm.messageInput.required,"data":_vm.savedStep ? _vm.savedStep[_vm.messageInput.name] : null},on:{"change":_vm.saveStep}}):_vm._e(),(_vm.messageInput.tooltip)?_c('FormSideTooltip',{attrs:{"content":_vm.messageInput.tooltip}}):_vm._e()],1):_vm._e(),(_vm.content.uploads)?_c('div',{staticClass:"form__input"},[_c('FormFiles',{staticClass:"form__files form__input",attrs:{"files":_vm.savedFiles,"data":_vm.content.uploads},on:{"upload:start":_vm.onUploadStart,"upload:done":_vm.onUploadDone}}),(_vm.content.uploads.tooltip)?_c('FormSideTooltip',{attrs:{"content":_vm.content.uploads.tooltip}}):_vm._e()],1):_vm._e(),(_vm.content.realisations)?_c('div',{staticClass:"form__input"},[_c('FormProjects',{staticClass:"form__projects form__input",attrs:{"name":"inspirations","content":_vm.content.realisations,"value":_vm.savedStep && _vm.savedStep.selectedRealisation
                  ? _vm.savedStep.selectedRealisation
                  : []}}),(_vm.content.realisations.tooltip)?_c('FormSideTooltip',{attrs:{"content":_vm.content.realisations.tooltip}}):_vm._e()],1):_vm._e(),(_vm.moodboard)?_c('Moodboard',{staticClass:"form__input",attrs:{"picture":_vm.moodboard.picture,"label":_vm.content.moodboard.label,"title":_vm.content.moodboard.title,"text":_vm.content.moodboard.htmltext}}):_vm._e(),(_vm.surveyInput)?_c('FormSelect',{staticClass:"form__input",attrs:{"name":_vm.surveyInput.name,"options":_vm.surveyInput.options || [],"label":_vm.surveyInput.label,"required":_vm.surveyInput.required,"placeholder":_vm.surveyInput.placeholder,"value":_vm.savedStep[_vm.surveyInput.name]},on:{"input":function($event){return _vm.onChange($event, _vm.surveyInput.name)}}}):_vm._e(),_vm._l((_vm.content.inputs.filter(
              function (input) { return input.slug === 'checkbox'; }
            )),function(item,index){return [(item.slug == 'checkbox')?_c('FormCheckbox',{key:("form-checkbox-" + index),class:((item.slug) + " " + (item.modifiers)),attrs:{"name":item.name,"required":item.required,"checked":_vm.savedStep[item.name]},on:{"change":function($event){return _vm.onChange($event, item.name)}}},[_c('span',{domProps:{"innerHTML":_vm._s(item.label)}}),(item.required)?_c('span',{staticClass:"form-required"},[_vm._v("*")]):_vm._e()]):_vm._e()]}),_c('FormRecaptcha',{staticClass:"form__confirmation__recaptcha mt-m",attrs:{"sitekey":_vm.chrome.recaptchaKey}})],2),(invalid)?_c('FormFeedback',{staticClass:"form__feedback",attrs:{"content":"form-incomplete","icon":"alert"}}):_vm._e(),(_vm.status === 'error' && !invalid)?_c('FormFeedback',{staticClass:"form__feedback",attrs:{"content":_vm.message || _vm.$t('form-error'),"icon":"alert"}}):_vm._e(),_c('fieldset',{staticClass:"form__send"},[_c('div',{staticClass:"form__send__action"},[_c('GAction',{class:{ disabled: _vm.isUploading },attrs:{"content":{
                label: _vm.content.back.label,
                icon: 'leftArrow',
                url: _vm.content.prev,
                modifiers: ['reverse', 'btn'],
              }}})],1),_c('div',{staticClass:"form__send__action"},[(_vm.isUploading)?_c('UiLoadingDots',{staticClass:"form-loading"}):_vm._e(),_c('GAction',{class:{
                disabled: _vm.isUploading,
                sending: _vm.status === 'submitting',
              },attrs:{"content":{
                label: _vm.content.submit.label,
                icon: _vm.content.submit.icon,
                tag: 'button',
                type: 'submit',
                modifiers: ['filled', 'btn'],
              },"disabled":_vm.isUploading,"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.onSubmitClick()}}})],1)])],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }